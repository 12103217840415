import { tokens } from '../tokens';

export const fr = {
    [tokens.common.languageChanged]: 'Langue changée',
    [tokens.nav.archive]: 'Archives',
    [tokens.nav.academy]: 'Académie',
    [tokens.nav.account]: 'Mon compte',
    [tokens.nav.analytics]: 'Statistiques',
    [tokens.nav.auth]: 'Auth',
    [tokens.nav.blog]: 'Blog',
    [tokens.nav.browse]: 'Browse',
    [tokens.nav.calendar]: 'Calendrier',
    [tokens.nav.chat]: 'Chat',
    [tokens.nav.checkout]: 'Checkout',
    [tokens.nav.concepts]: 'Outils',
    [tokens.nav.contact]: 'Contact',
    [tokens.nav.course]: 'Course',
    [tokens.nav.create]: 'Create',
    [tokens.nav.crypto]: 'Crypto',
    [tokens.nav.customers]: 'Clients',
    [tokens.nav.dashboard]: 'Accueil',
    [tokens.nav.details]: 'Détails',
    [tokens.nav.ecommerce]: 'E-Commerce',
    [tokens.nav.pension]: 'Pensions',
    [tokens.nav.edit]: 'Éditer',
    [tokens.nav.error]: 'Erreur',
    [tokens.nav.feed]: 'Feed',
    [tokens.nav.fileManager]: 'File Manager',
    [tokens.nav.finance]: 'Finance',
    [tokens.nav.fleet]: 'Fleet',
    [tokens.nav.forgotPassword]: 'Forgot Password',
    [tokens.nav.invoiceList]: 'Factures',
    [tokens.nav.jobList]: 'Job Listings',
    [tokens.nav.kanban]: 'Kanban',
    [tokens.nav.list]: 'Liste',
    [tokens.nav.treatment]: 'Traitement',
    [tokens.nav.login]: 'Connexion',
    [tokens.nav.logistics]: 'Logistics',
    [tokens.nav.mail]: 'Mail',
    [tokens.nav.management]: 'Gestion',
    [tokens.nav.orderList]: 'Orders',
    [tokens.nav.overview]: 'Accueil',
    [tokens.nav.pages]: 'Pages',
    [tokens.nav.postCreate]: 'Post Create',
    [tokens.nav.postDetails]: 'Post Details',
    [tokens.nav.postList]: 'Post List',
    [tokens.nav.pricing]: 'Pricing',
    [tokens.nav.productList]: 'Products',
    [tokens.nav.profile]: 'Profile',
    [tokens.nav.register]: 'Register',
    [tokens.nav.resetPassword]: 'Reset Password',
    [tokens.nav.socialMedia]: 'Social Media',
    [tokens.nav.verifyCode]: 'Verify Code'
};
