import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Chip, SvgIcon } from '@mui/material';
import BarChartSquare02Icon from '../../icons/untitled-ui/duocolor/bar-chart-square-02';
import Building04Icon from '../../icons/untitled-ui/duocolor/building-04';
import CalendarIcon from '../../icons/untitled-ui/duocolor/calendar';
import HomeSmileIcon from '../../icons/untitled-ui/duocolor/home-smile';
import ReceiptCheckIcon from '../../icons/untitled-ui/duocolor/receipt-check';
import Users03Icon from '../../icons/untitled-ui/duocolor/users-03';
import { tokens } from 'src/locales/tokens';
import { paths } from 'src/paths';

export const useSections = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: t(tokens.nav.overview),
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            )
          },
          {
            title: t(tokens.nav.account),
            path: paths.dashboard.account,
            icon: (
              <SvgIcon fontSize="small">
                <Users03Icon />
              </SvgIcon>
            )
          }
        ]
      },
      {
        subheader: t(tokens.nav.management),
        items: [
          {
            title: t(tokens.nav.customers),
            path: paths.dashboard.customers.index,
            icon: (
                <SvgIcon fontSize="small">
                  <Users03Icon />
                </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.list),
                path: paths.dashboard.customers.index
              },
              {
                title: "Créer",
                path: paths.dashboard.customers.create
              },
              {
                title: t(tokens.nav.details),
                path: paths.dashboard.customers.details,
                disabled: true,
              },
              {
                title: t(tokens.nav.edit),
                path: paths.dashboard.customers.edit,
                disabled: true,
              }
            ]
          },
          {
            title: t(tokens.nav.pension),
            path: paths.dashboard.pensions.index,
            icon: (
                <SvgIcon fontSize="small">
                  <Building04Icon />
                </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.list),
                path: paths.dashboard.pensions.index
              },
              {
                title: "Créer",
                path: paths.dashboard.pensions.create
              },
              {
                title: t(tokens.nav.edit),
                path: paths.dashboard.pensions.edit,
                disabled: true,
              }
            ]
          }
        ]
      },
      {
        subheader: t(tokens.nav.concepts),
        items: [
          {
            title: t(tokens.nav.analytics),
            path: paths.dashboard.analytics,
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            )
          },
          {
            title: t(tokens.nav.invoiceList),
            path: paths.dashboard.invoices.index,
            icon: (
              <SvgIcon fontSize="small">
                <ReceiptCheckIcon />
              </SvgIcon>
            ),
            items: [
              {
                title: t(tokens.nav.treatment),
                path: paths.dashboard.invoices.index
              },
              {
                title: t(tokens.nav.archive),
                path: paths.dashboard.invoices.archives,
              },
              {
                title: t(tokens.nav.details),
                path: paths.dashboard.invoices.details,
                disabled: true,
              }
            ]
          },
          {
            title: t(tokens.nav.calendar),
            path: paths.dashboard.calendar,
            icon: (
              <SvgIcon fontSize="small">
                <CalendarIcon />
              </SvgIcon>
            ),
            label: (
              <Chip
                color="primary"
                label="Nouveau"
                size="small"
              />
            )
          }
        ]
      }
    ];
  }, [t]);
};
