import {useAuth} from "./use-auth";

export const useMockedUser = () => {
  // To get the user from the authContext, you can use
  const { user, isAuthenticated } = useAuth();

  return {
    id: '5e86809283e28b96d2d38537',
    avatar: '/assets/avatars/avatar-anika-visser.png',
    name: user.name,
    email: user.email,
    isAuthenticated: isAuthenticated
  };
};
