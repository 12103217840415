import { createContext, useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Issuer } from 'src/utils/auth';
import axios from 'axios';
import {paths} from "../../paths";
import {useRouter} from "../../hooks/use-router";
import moment from "moment";
import toast from 'react-hot-toast';
import {Warning} from "@mui/icons-material";


const STORAGE_KEY = 'accessToken';

var ActionType;
(function (ActionType) {
  ActionType['INITIALIZE'] = 'INITIALIZE';
  ActionType['SIGN_IN'] = 'SIGN_IN';
  ActionType['SIGN_UP'] = 'SIGN_UP';
  ActionType['SIGN_OUT'] = 'SIGN_OUT';
})(ActionType || (ActionType = {}));

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  },
  SIGN_IN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_UP: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  SIGN_OUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

export const AuthContext = createContext({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const router = useRouter();

  useEffect(() => {
    const checkTokenExpiration = () => {

      console.log('Checking token expiration')

      const now = Date.now();
      const tokenExpiration = localStorage.getItem('tokenExpiration');

      if (tokenExpiration < now) {
        // Token has expired, redirect to login
        console.log('Token has expired, redirect to login')

        dispatch({
          type: ActionType.SIGN_OUT
        })

        router.push(paths.index);
      } else {
        const timeRemaining = tokenExpiration - now;
        const timeRemainingInMinutes = moment(parseInt(timeRemaining))

        // Show toast 5 minutes before token expiration
        if (timeRemainingInMinutes.minutes() <= 5) {
          toast.success(`Cette session expire dans ${timeRemainingInMinutes.format('m')} minutes.`, {
            duration: 10000,
            position: 'top-right',
            icon: <Warning color="warning" />,
          });
        }
      }
    };

    const tokenCheckInterval = setInterval(checkTokenExpiration, 1000 * 60);

    return () => clearInterval(tokenCheckInterval); // Cleanup
  }, []);

  const initialize = useCallback(async () => {
    try {
      const accessToken = window.localStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        const userReq = await axios.post(`${process.env.REACT_APP_API_URL}/me`, { accessToken }, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem(STORAGE_KEY)}`
          }
        })
        console.log(userReq)
        const user = userReq.data.user;

        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);

      dispatch({
        type: ActionType.INITIALIZE,
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  }, [dispatch]);

  useEffect(() => {
      initialize();
    }, []);

  const signIn = useCallback(async (email, password) => {
    const resp = await axios.post(`${process.env.REACT_APP_API_URL}/login`, { email, password })
      .catch(function (error) {
        throw error.response.data;
      });

    const { user, token } = resp.data;

    localStorage.setItem(STORAGE_KEY, token);
    localStorage.setItem('tokenExpiration', moment().add(29, 'minute').valueOf().toString());

    dispatch({
      type: ActionType.SIGN_IN,
      payload: {
        user
      }
    });
  }, [dispatch]);

  const signUp = useCallback(async (email, name, password, password_confirmation) => {

    const resp = await axios.post(`${process.env.REACT_APP_API_URL}/signup`, { email, name, password, password_confirmation })
      .catch(function (error) {
        throw error.response.data;
      });

    const { user, token } = resp.data;

    localStorage.setItem(STORAGE_KEY, token);

    dispatch({
      type: ActionType.SIGN_UP,
      payload: {
        user
      }
    });
  }, [dispatch]);

  const signOut = useCallback(async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/logout`, null, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem(STORAGE_KEY)}`
      }
    }).then(response => {
      localStorage.removeItem(STORAGE_KEY);
      localStorage.removeItem('tokenExpiration');
      dispatch({ type: ActionType.SIGN_OUT });
    }).catch(function (error) {
      throw error.response.data;
    });
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signUp,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
